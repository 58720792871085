import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Index",
    meta: {
      title: '精推宝-官网'   // 标题设置
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Index.vue"),
  },
]

Vue.use(VueRouter);

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
