<template>
<div id="app">
    <router-view v-wechat-title='$route.meta.title' img-set="./assets/logo@1x.png"/>
</div>
</template>

<script>
export default {
    name: "app",
    components: {},
};
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
}

#app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-width: 1280px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #0D2D53;
}
</style>
